import * as React from "react";
import "react-multi-carousel/lib/styles.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Layout from "../components/Layout/Layout";

const IndexPage = () => {
  return (
    <>
      <Layout></Layout>
      {/* <HeroSection/> */}
      {/* <Animation/> */}
    </>
  );
};

export default IndexPage;
