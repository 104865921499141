import React from "react";

const SVGComponent = ({ color }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.3333 21.56V25.56C28.3348 25.9313 28.2588 26.2989 28.11 26.6391C27.9612 26.9793 27.7431 27.2848 27.4694 27.5358C27.1958 27.7868 26.8728 27.9779 26.521 28.0969C26.1692 28.2159 25.7965 28.2601 25.4267 28.2266C21.3238 27.7808 17.3827 26.3788 13.92 24.1333C10.6984 22.0862 7.96711 19.3549 5.92 16.1333C3.66663 12.6549 2.26432 8.69463 1.82666 4.5733C1.79334 4.20459 1.83716 3.83298 1.95533 3.48213C2.0735 3.13128 2.26343 2.80889 2.51302 2.53546C2.76261 2.26204 3.0664 2.04358 3.40505 1.894C3.7437 1.74441 4.10978 1.66698 4.48 1.66663H8.48C9.12707 1.66026 9.75439 1.8894 10.245 2.31134C10.7356 2.73328 11.0561 3.31923 11.1467 3.95997C11.3155 5.24006 11.6286 6.49694 12.08 7.70663C12.2594 8.18387 12.2982 8.70252 12.1919 9.20114C12.0855 9.69976 11.8385 10.1574 11.48 10.52L9.78666 12.2133C11.6847 15.5514 14.4486 18.3152 17.7867 20.2133L19.48 18.52C19.8425 18.1615 20.3002 17.9144 20.7988 17.8081C21.2974 17.7018 21.8161 17.7406 22.2933 17.92C23.503 18.3714 24.7599 18.6845 26.04 18.8533C26.6877 18.9447 27.2792 19.2709 27.702 19.77C28.1249 20.269 28.3496 20.9061 28.3333 21.56Z" stroke="#ED1C24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default SVGComponent;
