import React from "react";

const ContributionIcon = (props) => {
    const {color, bcolor} = props
  return (
    <svg
      width="76"
      height="138"
      viewBox="0 0 76 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.427 84.4L39.5001 85.3269L38.573 84.3999C36.795 82.6218 34.3834 81.6229 31.8689 81.6229C29.3543 81.6229 26.9427 82.6218 25.1647 84.3999C23.3866 86.1779 22.3877 88.5895 22.3877 91.1041C22.3877 93.6186 23.3866 96.0302 25.1647 97.8082L26.7988 99.4424L38.793 111.437C39.1835 111.827 39.8167 111.827 40.2072 111.437L52.2014 99.4424L53.8354 97.8084C53.8354 97.8084 53.8355 97.8083 53.8355 97.8082C54.7162 96.928 55.4147 95.8829 55.8913 94.7326C56.368 93.5822 56.6133 92.3492 56.6133 91.1041C56.6133 89.8589 56.368 88.6259 55.8913 87.4755C55.4148 86.3253 54.7162 85.2802 53.8357 84.4C52.9554 83.5193 51.9103 82.8207 50.7599 82.3441C49.6095 81.8674 48.3766 81.6221 47.1314 81.6221C45.8862 81.6221 44.6532 81.8674 43.5028 82.3441C42.3525 82.8207 41.3074 83.5192 40.4272 84.3999C40.4271 84.3999 40.4271 84.4 40.427 84.4Z"
        fill="#C4C4C4"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.4212 85.8139L52.4215 85.8142C53.1164 86.5088 53.6676 87.3334 54.0437 88.2411C54.4198 89.1487 54.6133 90.1216 54.6133 91.1041C54.6133 92.0865 54.4198 93.0594 54.0437 93.967C53.6676 94.8747 53.1164 95.6993 52.4215 96.3939L52.4213 96.394L50.7872 98.0282L39.5001 109.315L28.213 98.0282L26.5789 96.394C25.1759 94.991 24.3877 93.0882 24.3877 91.1041C24.3877 89.1199 25.1759 87.2171 26.5789 85.8141C27.9819 84.4111 29.8847 83.6229 31.8689 83.6229C33.853 83.6229 35.7558 84.4111 37.1588 85.8141L38.793 87.4482C39.1835 87.8388 39.8167 87.8388 40.2072 87.4482L41.8414 85.8141L41.8415 85.8139C42.5361 85.119 43.3607 84.5678 44.2684 84.1917C45.176 83.8156 46.1489 83.6221 47.1314 83.6221C48.1138 83.6221 49.0867 83.8156 49.9943 84.1917C50.902 84.5678 51.7266 85.119 52.4212 85.8139Z"
        fill={color}
        stroke="#E7E7E7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 70C1 62.268 7.26801 56 15 56H61C68.732 56 75 62.268 75 70V137H1V70Z"
        stroke={color}
        stroke-width="2"
      />
      <circle
        cx="55"
        cy="14"
        r="13"
        fill={bcolor ? bcolor : '#E7E7E7'}
        stroke={color}
        stroke-width="2"
      />
      <circle
        cx="37.5"
        cy="28.5"
        r="17.5"
        fill={bcolor ? bcolor : '#E7E7E7'}
        stroke={color}
        stroke-width="2"
      />
      <rect
        x="11"
        y="38"
        width="55"
        height="13"
        rx="1"
        fill={bcolor ? bcolor : '#E7E7E7'}
        stroke={color}
        stroke-width="2"
      />
    </svg>
  );
};

export default ContributionIcon;
