import React from "react";

const SVGComponent = ({ color }) => (
  <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.6139 0.877152C13.2528 0.596288 12.7472 0.596288 12.3861 0.877152L0.386059 10.2105C0.142473 10.3999 0 10.6912 0 10.9998V25.6665C0 26.639 0.386308 27.5716 1.07394 28.2592C1.76157 28.9469 2.69421 29.3332 3.66667 29.3332H9H17H22.3333C23.3058 29.3332 24.2384 28.9469 24.9261 28.2592C25.6137 27.5716 26 26.639 26 25.6665V10.9998C26 10.6912 25.8575 10.3999 25.6139 10.2105L13.6139 0.877152ZM18 27.3332H22.3333C22.7754 27.3332 23.1993 27.1576 23.5118 26.845C23.8244 26.5325 24 26.1085 24 25.6665V11.4889L13 2.93337L2 11.4889V25.6665C2 26.1085 2.17559 26.5325 2.48816 26.845C2.80072 27.1576 3.22464 27.3332 3.66667 27.3332H8V14.9998C8 14.4476 8.44771 13.9998 9 13.9998H17C17.5523 13.9998 18 14.4476 18 14.9998V27.3332ZM10 27.3332V15.9998H16V27.3332H10Z" fill="#F7941D"/>
</svg>

);

export default SVGComponent;
